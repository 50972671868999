import * as React from 'react'
import styled from 'styled-components'
import Layout from '@components/Layout'

import UI, { H3, H4, Text, Box, IFrame } from '@components/UI'

const Title = styled(UI.H2).attrs({
  pb: 1
})``

const By = styled(H4).attrs({
  color: 'dark',
  pb: 1
})``

const Link = styled(UI.Link).attrs({})``

const VideoBox = styled(Box).attrs({
  pt: 4,
  mt: 2
})``

const ASPECT_RATIO = 121 / 68

type ReviewProps = {
  pageContext: {
    title: string
    by: string
    url: string
    content: string
  }
}

const Review = ({
  pageContext: { title, by, url, displayUrl, youtubeUrl, content }
}: ReviewProps) => {
  return (
    <Layout goBackTo="press" title={title}>
      <Title>{title}</Title>
      <By>{by}</By>
      <Text pt={1} pb={3} color="black" fontWeight="medium">
        Link to original article: <Link href={url}>{displayUrl}</Link>
      </Text>

      {content ? (
        <Box py={4} my={2}>
          <Text
            dangerouslySetInnerHTML={{ __html: content.replace(/\n/g, '\n\n') }}
          ></Text>
        </Box>
      ) : null}
      {youtubeUrl ? (
        <VideoBox>
          <IFrame
            url={youtubeUrl}
            title={title}
            getHeight={(width: number) => width / ASPECT_RATIO}
          />
        </VideoBox>
      ) : null}
    </Layout>
  )
}

export default Review
